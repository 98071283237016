<script setup lang="ts">
import { localeOptions } from '~/utils/locale'

const modal = useModal()

const { $log } = useNuxtApp()
const { t, setLocale } = useI18n()

function onSetLocale(localeCode: string) {
  $log.debug('Locale: Switching locale from modal', localeCode)
  setLocale(localeCode)
  modal.close()
}
</script>

<template>
  <UModal>
    <UCard>
      <template #header>{{ t('changeLanguage') }}</template>

      <div class="grid gap-4 md:grid-cols-2">
        <UButton
          v-for="localeOption in localeOptions"
          :key="localeOption.value"
          :icon="localeOption.icon"
          variant="ghost"
          size="xl"
          @click.stop="onSetLocale(localeOption.value)"
        >
          {{ localeOption.label }}
        </UButton>
      </div>

      <template #footer>
        <UButton block @click="modal.close">{{ t('close') }}</UButton>
      </template>
    </UCard>
  </UModal>
</template>
