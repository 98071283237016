import { Capacitor } from '@capacitor/core'

import { checkAccessTokenLifespan, extractTokensFromResponse } from '~/utils/auth/lifespan'
import { appVersion } from '~~/shared/version'

export function useApi() {
  const config = useRuntimeConfig()

  const apiStatus = useApiStatus()
  const authStore = useAuthStore()

  const controller = new AbortController()

  const fetchApi = $fetch.create({
    baseURL: config.public.platformApiBaseUrl,
    signal: controller.signal,

    async onRequest({ options, request }) {
      const { $network, $log } = useNuxtApp()
      const { tenantId } = useTenant()

      if ($network.isMaintenanceModeEnabled.value) {
        console.log('Cancelling API call due to maintenance mode')
        controller.abort()
      }

      const byPassTokenCheckPaths = ['/auth/v1/login', '/auth/v1/logout', '/tenant/v1/domain']
      const shouldBypassTokenCheck = byPassTokenCheckPaths.some(path => request.startsWith(path))

      if (!shouldBypassTokenCheck) {
        await checkAccessTokenLifespan()
      }

      options.headers = {
        ...options.headers,
        Authorization: authStore.isLoggedIn ? `Bearer ${authStore.getAccessToken()}` : '',
        'Tenant-Id': tenantId,
        'Guest-Id': $log.getGuestId()!,
        'App-Platform': Capacitor.getPlatform(),
        'App-Version': appVersion
      }

      apiStatus.value.isLoading = true
      apiStatus.value.response.error = null
      apiStatus.value.response.message = null
      apiStatus.value.response.status = null

      // Check to see if we are online or in maintenance mode
      $network.checkConnection()
    },

    onResponse({ response }) {
      if (response.ok) {
        // Auto set access token if it's returned from any response
        extractTokensFromResponse(response)
      }

      apiStatus.value.isLoading = false
    },

    onResponseError({ response }) {
      const { $log } = useNuxtApp()

      const message = response._data?.message || response._data?.statusMessage || response.statusText
      const responseCode = response.status

      apiStatus.value.response.message = message

      apiStatus.value.response.error = message

      if (responseCode >= 500) {
        $log.error(`API error: ${response.url}`, message)
      }

      apiStatus.value.isLoading = false
    }
  })

  const api = async <T>(url: string, options?: any): Promise<T> => {
    const response = await fetchApi(url, options)

    return response
  }

  return { api, apiStatus }
}
