export default defineNuxtPlugin({
  name: 'error-handler',
  dependsOn: ['sentry'],
  parallel: true,
  setup(nuxtApp) {
    // We load $sentry from useNuxtApp so it's typed correctly
    const { $sentry } = useNuxtApp()

    nuxtApp.hook('vue:error', (error, instance, info) => {
      console.log('Hooked error', error, instance, info)
      $sentry.captureException(error)
    })

    nuxtApp.hook('app:error', error => {
      console.log('Startup error', error)
      $sentry.captureException(error)
    })
  }
})
