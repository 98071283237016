import { Capacitor } from '@capacitor/core'
import { Network } from '@capacitor/network'
import { isDeepEqual } from 'remeda'

export interface Web {
  version: string
  releaseDate: string
}
export interface AppVersion {
  web: Web
}

export interface Notifications {
  enabled: boolean
}
export interface MaintenanceMode {
  notifications: Notifications
  message: string
  resolvedByDateUTC: string
  enabled: boolean
  bypassed: boolean
}

export interface PingResponse {
  maintenanceMode: MaintenanceMode
  appVersion: AppVersion
}

export default defineNuxtPlugin({
  name: 'network-monitor',
  dependsOn: ['logger'],
  parallel: true,
  setup() {
    const {
      public: { pingApiUrl }
    } = useRuntimeConfig()

    const { $log } = useNuxtApp()

    let offlineRetryTimer: ReturnType<typeof setInterval>
    let maintenanceModeRetryTimer: ReturnType<typeof setInterval>
    let lastCheckedNetworkTimestamp = 0

    const network = {
      maintenanceMode: ref<MaintenanceMode>(),
      isMaintenanceModeEnabled: ref(false),
      isOnline: ref(true),
      checkConnection: async function (forceCheck = false) {
        const secondsSinceLastCheck = Math.ceil((Date.now() - lastCheckedNetworkTimestamp) / 1000)

        // force allows us to skip the timer lockout
        if (secondsSinceLastCheck < 10 && !forceCheck) {
          return
        }

        lastCheckedNetworkTimestamp = Date.now()

        try {
          const pingResult = await $fetch<PingResponse>(`${pingApiUrl}/?time=${Date.now()}`, {
            timeout: 5000
          })

          if (typeof pingResult.maintenanceMode?.enabled !== 'undefined') {
            // No need to keep hammering store mutations if nothing has changed
            if (!isDeepEqual(pingResult.maintenanceMode, this.maintenanceMode.value)) {
              if (pingResult.maintenanceMode.enabled && !pingResult.maintenanceMode.bypassed) {
                $log.warn('Entering maintenance mode', maintenanceModeRetryTimer)

                navigateTo('/maintenance')

                // Once offline has been detected, let's check every x seconds since the
                // online / offline events aren't reliable enough
                if (!maintenanceModeRetryTimer) {
                  maintenanceModeRetryTimer = setInterval(network.checkConnection, 5000)
                }
              } else if (maintenanceModeRetryTimer) {
                // If we were previously polling for a connection while in maintenance mode then let's clear that timer
                clearInterval(maintenanceModeRetryTimer)

                $log.debug('Exiting maintenance mode, returning to root')

                navigateTo('/')
              }

              this.maintenanceMode.value = pingResult.maintenanceMode

              this.isMaintenanceModeEnabled.value =
                pingResult.maintenanceMode.enabled && !pingResult.maintenanceMode.bypassed
            }
          }

          this.isOnline.value = true

          // If we were previously polling for a connection while offline then let's clear that timer
          if (offlineRetryTimer) {
            clearInterval(offlineRetryTimer)
          }

          return { isMaintenanceModeEnabled: this.isMaintenanceModeEnabled.value, isOnline: this.isOnline.value }
        } catch (error) {
          $log.warn('Internet is offline', error)

          this.isOnline.value = false

          // Once offline has been detected, let's check every x seconds since the
          // online / offline events aren't reliable enough
          if (!offlineRetryTimer) {
            offlineRetryTimer = setInterval(network.checkConnection, 5000)
          }
        }
      },

      isSlowConnection: function () {
        if (import.meta.server) {
          return
        }

        // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/connection
        const cn = (navigator as any).connection as { saveData: boolean; effectiveType: string } | null

        if (cn && (cn.saveData || /2g/.test(cn.effectiveType))) {
          return true
        }

        return false
      }
    }

    // Extra automated listeners for browser online/offline events to re-trigger our checks
    // Since we only check before/after API calls by default

    if (import.meta.client && window) {
      window.addEventListener('online', () => {
        $log.debug('Browser online event')
        network.checkConnection(true)
      })

      window.addEventListener('offline', () => {
        $log.debug('Browser offline event')
        network.checkConnection(true)
      })
    }

    if (Capacitor.isNativePlatform()) {
      Network.addListener('networkStatusChange', status => {
        $log.debug('Native network event', status)

        network.checkConnection(true)
      })
    }

    return {
      provide: {
        network
      }
    }
  }
})
