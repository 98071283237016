import type { LogInsert, LogSelect } from '~~/server/database/schema/log'
import type { Pagination } from '~~/server/utils/db/pagination'

export function useLogService() {
  const { apiStatus, api } = useApi()
  const { activeTenant } = useTenant()

  async function sendLogs(logs: LogInsert[]) {
    // In case we have logs to send immediately before we have a valid tenant ready
    if (!activeTenant.value?.id) {
      return false
    }

    return await api<{ success: boolean }>(`/log/v1`, {
      method: 'POST',
      body: logs
    })
  }

  async function getUserLogs(userId: string, dataParams: Pagination) {
    return await api<LogSelect[]>(`/log/v1/user/${userId}`, {
      method: 'GET',
      params: dataParams
    })
  }

  return { apiStatus, sendLogs, getUserLogs }
}
