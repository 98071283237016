import * as Sentry from '@sentry/vue'

import { getPlatform, isNativePlatform } from '~/utils/native-app/capacitor'
import { appVersion } from '~~/shared/version'

export default defineNuxtPlugin({
  name: 'sentry',
  dependsOn: ['device'],
  parallel: true,
  setup(nuxtApp) {
    if (import.meta.env.TEST) {
      return
    }

    const {
      public: { errorReportingEnabled, sentry }
    } = useRuntimeConfig()

    if (!sentry.client.dsn) {
      console.error('Sentry DSN not found in environment')
      return
    }

    Sentry.init({
      enabled: errorReportingEnabled,
      app: nuxtApp.vueApp,
      release: appVersion,
      dsn: sentry.client.dsn,
      // debug: true,
      environment: sentry.environment,
      ignoreErrors: [/Network Error/i, /Fetch Error/i]
    })

    Sentry.setTag('app-platform', getPlatform())
    Sentry.setTag('app-native', isNativePlatform())

    return {
      provide: {
        sentry: Sentry
      }
    }
  }
})
