import { init, posthog } from '~/utils/analytics'
import { getPlatform, isNativePlatform } from '~/utils/native-app/capacitor'
import { appVersion } from '~~/shared/version'

export default defineNuxtPlugin({
  name: 'analytics',
  dependsOn: ['device'],
  setup() {
    if (import.meta.env.TEST) {
      return
    }

    const config = useRuntimeConfig()
    const router = useRouter()

    if (config.public.analyticsReportingEnabled) {
      init({
        posthogDsn: config.public.posthog.dsn,
        apiHost: config.public.posthog.apiHost,
        appEnvironment: config.public.appEnvironment,
        appCountryCode: config.public.appCountryCode,
        appVersion,
        platform: getPlatform(),
        isNativePlatform: isNativePlatform()
      })

      router.afterEach(to => {
        posthog.capture('$pageview', {
          $current_url: to.fullPath
        })
      })

      if (import.meta.client) {
        window.addEventListener('beforeunload', () => {
          posthog.capture(
            '$pageleave',
            {
              from_url: window.location.href
            },
            { send_instantly: true }
          )
        })
      }
    }
  }
})
