import { posthog } from 'posthog-js'

export interface InitConfig {
  posthogDsn: string
  apiHost: string
  appEnvironment: string
  appCountryCode: string
  appVersion: string
  platform: string
  isNativePlatform: boolean
}

export function init(config: InitConfig) {
  posthog.init(config.posthogDsn, {
    capture_pageview: false,
    capture_pageleave: true,
    api_host: config.apiHost,
    disable_session_recording: true,
    advanced_disable_decide: true,
    advanced_disable_feature_flags: true,
    advanced_disable_feature_flags_on_first_load: true,
    enable_recording_console_log: false,
    autocapture: false
  })

  posthog.register({
    environment: config.appEnvironment,
    countryCode: config.appCountryCode,
    release: config.appVersion,
    platform: config.platform,
    native: config.isNativePlatform
  })
}

export { posthog }
