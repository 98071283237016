import { addStatusTapListener, loadCapacitor, setStatusBarTheme } from '~/utils/native-app/capacitor'

export default defineNuxtPlugin({
  name: 'device',
  async setup() {
    await loadCapacitor()

    if (isNativePlatform()) {
      setStatusBarTheme('splash-screen')

      addStatusTapListener()
    }
  }
})
