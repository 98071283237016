export default defineAppConfig({
  ui: {
    primary: 'sky',
    gray: 'cool',
    icons: {
      dynamic: true
    },
    button: {
      default: {
        size: 'lg'
      }
    },
    input: {
      default: {
        size: 'lg',
        loadingIcon: 'i-line-md-loading-twotone-loop'
      }
    },
    select: {
      default: {
        size: 'lg'
      }
    },
    alert: {
      wrapper: 'overflow-visible'
    },
    label: {
      base: 'font-bold'
    },
    selectMenu: {
      default: {
        size: 'lg'
      }
    },
    card: {
      rounded: 'rounded-lg',
      header: {
        base: 'flex flex-wrap items-center justify-between'
      }
    },
    notifications: {
      position: 'bottom-auto top-0'
    },
    formGroup: {
      label: {
        wrapper: 'font-bold',
        class: 'font-bold'
      }
    },
    footer: {
      top: {
        wrapper: 'border-t border-gray-200 dark:border-gray-800',
        container: 'py-8 lg:py-16'
      },
      bottom: {
        wrapper: 'border-t border-gray-200 dark:border-gray-800'
      }
    },
    page: {
      hero: {
        wrapper: 'lg:py-24'
      }
    },
    dashboard: {
      layout: {
        wrapper: 'ios-safe-position-top ios-safe-padding-bottom'
      },
      slideover: {
        header: {
          padding: 'p-4 ios-safe-padding-top'
        }
      }
    }
  }
})
