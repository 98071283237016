import { NativeBiometric, BiometryType } from '@capgo/capacitor-native-biometric'
import { isNativePlatform, isIos } from '~/utils/native-app/capacitor'

export function useBiometrics() {
  const { $log } = useNuxtApp()

  const biometryType = ref(0)

  async function performBiometricVerification() {
    if (!isNativePlatform()) {
      return false
    }

    const result = await NativeBiometric.isAvailable()

    if (!result.isAvailable) {
      $log.debug('Biometrics not available')
      return false
    }

    try {
      await NativeBiometric.verifyIdentity({
        reason: 'For easy log in',
        title: 'Log in',
        subtitle: 'Maybe add subtitle here?',
        description: 'Maybe a description too?'
      })

      return true
    } catch (error) {
      $log.debug('Biometric verification error', error)
      return false
    }
  }

  async function isBiometricsAvailable() {
    if (!isNativePlatform()) {
      return false
    }

    try {
      const result = await NativeBiometric.isAvailable()

      biometryType.value = result.biometryType

      return result.isAvailable
    } catch (_error) {
      return false
    }
  }

  function getBiometricsConfig() {
    const { t } = useI18n()

    const biometryText = ref('Biometrics')
    const biometryIcon = ref('i-mynaui-face-id')

    if (isIos()) {
      if (biometryType.value === BiometryType.FACE_ID) {
        biometryText.value = t('faceId')
        biometryIcon.value = 'i-mynaui-face-id'
      }

      if (biometryType.value === BiometryType.TOUCH_ID) {
        biometryText.value = t('touchId')
        biometryIcon.value = 'i-marketeq-touchid'
      }
    } else {
      // Android
      if (
        biometryType.value === BiometryType.FACE_AUTHENTICATION ||
        biometryType.value === BiometryType.IRIS_AUTHENTICATION
      ) {
        biometryText.value = t('faceUnlock')
        biometryIcon.value = 'i-mynaui-face-id'
      }

      if (biometryType.value === BiometryType.FINGERPRINT) {
        biometryText.value = t('fingerUnlock')
        biometryIcon.value = 'i-marketeq-touchid'
      }
    }

    return { biometryText, biometryIcon }
  }

  return { performBiometricVerification, isBiometricsAvailable, biometryType, getBiometricsConfig }
}
